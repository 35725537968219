import React, { useState, useEffect } from "react"
import { createClient } from '@supabase/supabase-js'
import './index.css'
import backgroundVideo from './assets/video/foirola.webm'
import { Instagram } from 'lucide-react'

const supabaseUrl = 'https://naiwfrlabpimbttmybfr.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5haXdmcmxhYnBpbWJ0dG15YmZyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc0NTEzNjAsImV4cCI6MjA0MzAyNzM2MH0.bWUzxpJwH93h6xlI29k2DcfuxQ3IILAxaZiDwYMgzgM'
const supabase = createClient(supabaseUrl, supabaseKey)

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +targetDate - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const addLeadingZero = (value) => value.toString().padStart(2, '0')

  return (
    <div className="flex justify-center space-x-4 text-2xl font-bold text-purple-300">
      <div className="text-center">
        <span>{addLeadingZero(timeLeft.days || 0)}</span>
        <p className="text-sm text-purple-400">Días</p>
      </div>
      <div className="text-center">
        <span>{addLeadingZero(timeLeft.hours || 0)}</span>
        <p className="text-sm text-purple-400">Horas</p>
      </div>
      <div className="text-center">
        <span>{addLeadingZero(timeLeft.minutes || 0)}</span>
        <p className="text-sm text-purple-400">Minutos</p>
      </div>
      <div className="text-center">
        <span>{addLeadingZero(timeLeft.seconds || 0)}</span>
        <p className="text-sm text-purple-400">Segundos</p>
      </div>
    </div>
  )
}

function App() {
  const [email, setEmail] = useState("")
  const [showBackToTop, setShowBackToTop] = useState(false)
  const [subscriptionMessage, setSubscriptionMessage] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      const { data, error } = await supabase
        .from('email_subscriptions')
        .insert([{ email: email }])

      if (error) {
        console.error("Supabase error:", error)
        throw error
      }

      console.log("Subscription successful:", data)
      setSubscriptionMessage("¡Gracias por suscribirte!")
      setEmail("")
    } catch (error) {
      console.error("Error subscribing email:", error)
      setSubscriptionMessage("Hubo un error. Por favor, inténtalo de nuevo.")
    } finally {
      setIsSubmitting(false)
    }
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <div className="relative flex flex-col min-h-screen overflow-hidden">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={backgroundVideo} type="video/webm" />
        Your browser does not support the video tag.
      </video>

      <div className="relative z-10 flex flex-col min-h-screen bg-gray-900 bg-opacity-95 text-gray-100 font-helvers">
        <header className="py-4 px-4 sm:px-6 lg:px-8 border-b border-gray-800 sticky top-0 bg-gray-900 bg-opacity-95 z-20">
          <div className="max-w-7xl mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/FOIROLA_Logo_LOGO-szvEC9ktnR5BHzWu0VUAQdfzE7RE1V.png"
                alt="Logo de Foirola"
                width={50}
                height={50}
                className="mr-4"
              />
            </div>
            <nav className="space-x-4">
              <button onClick={() => scrollToSection('about')} className="text-sm font-medium text-purple-300 hover:text-pink-400 transition-colors">Sobre Nosotros</button>
              <button onClick={() => scrollToSection('contact')} className="text-sm font-medium text-purple-300 hover:text-pink-400 transition-colors">Contacto</button>
            </nav>
          </div>
        </header>

        <main className="flex-grow">
          <section className="py-20 px-4 sm:px-6 lg:px-8 text-center">
            <div className="max-w-3xl mx-auto">
              <h1 className="text-4xl font-anticlaire font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 sm:text-5xl sm:tracking-tight lg:text-6xl mb-8 drop-shadow-lg">
                Se viene la cosa. ¿Estás preparado?
              </h1>
              <form onSubmit={handleSubmit} className="mb-8">
                <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Tu correo electrónico"
                    required
                    className="px-4 py-2 w-full sm:w-64 bg-gray-800 bg-opacity-75 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 shadow-lg"
                  />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white font-medium rounded-md hover:from-purple-700 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-300 shadow-lg disabled:opacity-50"
                  >
                    {isSubmitting ? 'Enviando...' : 'Suscribirse'}
                  </button>
                </div>
              </form>
              {subscriptionMessage && (
                <p className="mt-4 text-sm text-purple-300">{subscriptionMessage}</p>
              )}
              <CountdownTimer targetDate={new Date('2024-10-25T00:00:00')} />
            </div>
          </section>

          <section id="about" className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-800 bg-opacity-95">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-anticlaire font-extrabold text-purple-400 mb-4 drop-shadow-lg">Sobre Foirola</h2>
              <p className="text-xl text-gray-300 mb-6 shadow-lg">
                Tres zumbaos dándole voz al lore de nuestra tierra. <br />¿Estás preparado?
              </p>
              <p className="text-xl text-gray-300 shadow-lg">
                A través de nuestros esfuerzos creativos, aspiramos a mostrar el corazón y el alma de Fuengirola, dando vida a sus historias de maneras emocionantes e inesperadas.<br />Siempre desde el humor y la parodia.
              </p>
            </div>
          </section>

          <section id="contact" className="py-20 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto">
              <h2 className="text-3xl font-anticlaire font-extrabold text-purple-400 text-center mb-8 drop-shadow-lg">Contáctanos</h2>
              <form className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300">Nombre</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    className="mt-1 block w-full bg-gray-800 bg-opacity-75 border-gray-700 rounded-md shadow-lg focus:ring-purple-500 focus:border-purple-500 text-white"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300">Correo electrónico</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className="mt-1 block w-full bg-gray-800 bg-opacity-75 border-gray-700 rounded-md shadow-lg focus:ring-purple-500 focus:border-purple-500 text-white"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-300">Mensaje</label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    required
                    className="mt-1 block w-full bg-gray-800 bg-opacity-75 border-gray-700 rounded-md shadow-lg focus:ring-purple-500 focus:border-purple-500 text-white"
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-lg text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300"
                  >
                    Enviar
                  </button>
                </div>
              </form>
              <div className="mt-8 text-center">
                <p className="text-gray-300">O envíanos un correo directamente a:</p>
                <a href="mailto:foirola29640@gmail.com" className="text-purple-400 hover:text-pink-400 transition-colors font-bold">foirola29640@gmail.com</a>
              </div>
              <div className="mt-6 text-center">
                <a
                  href="https://www.instagram.com/foirola.brand"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-purple-400 hover:text-pink-400 transition-colors"
                >
                  <Instagram className="w-6 h-6 mr-2" />
                </a>
              </div>
            </div>
          </section>
        </main>

        <footer className="bg-gray-800 bg-opacity-95 border-t border-gray-700 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            <p className="text-base text-gray-400">&copy; {new Date().getFullYear()} Foirola. Todos los derechos reservados.</p>
          </div>
        </footer>

        {showBackToTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 p-2 rounded-full bg-purple-600 text-white shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-all duration-300 z-50"
            aria-label="Volver arriba"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}

export default App